<template>
  <v-app style="background-color:#eee;padding:20px;">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('forgot_password')"
      class="px-5 py-3 col-md-5"
      color="primary"
      style="margin:auto;"
    >
      <v-row>
        <v-col v-if="!success" cols="12">
          <v-text-field
            v-model="email"
            type="email"
            :label="$t('email')"
            class="purple-input"
          />
        </v-col>
        <v-col cols="12" v-if="error" style="color:red">{{ error }}</v-col>
        <v-col cols="12" v-if="success">{{ success }}</v-col>
        <v-col v-if="!success" cols="12" class="text-right">
          <v-btn @click="reset_password()" color="primary" class="mr-0">{{$t('confirm')}}</v-btn>
        </v-col>
        <v-col v-if="success" cols="12" class="text-right">
          <v-btn @click="back_to_login()" color="primary" class="mr-0">{{$t('back_to_login')}}</v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-app>
</template>

<script>
import * as firebase from "firebase";
import Vue from "vue";
export default {
  data() {
    return {
        email: "",
        error: null,
        success: null,
    };
  },
  mounted() {},
  methods: {
    reset_password: function(event) {
        console.log("reset clicked")
        firebase.auth().languageCode = "sl";
        var actionCodeSettings = {
          url: window.location.protocol +
                  "//" +
                  window.location.host +
                  "/login",
          handleCodeInApp: false
        };
        firebase.auth().sendPasswordResetEmail(this.email, actionCodeSettings).then(() => {
          this.error = null
          this.success = this.$t('check_reset_email')
        }).catch((errorMessage) => {
          console.log(errorMessage)
          this,this.success = null;
          switch (errorMessage.code) {
            case "auth/invalid-email":
              this.error = this.$t("invalid_email")
              break;
            case "auth/user-not-found":
              this.error = this.$t("invalid_user");
              break;
          }
        });
    },
    back_to_login: function(event) {
      this.$router.push("login");
    }
  },
};
</script>
